<script setup>
import Modal from "@/common/Components/Modal.vue";
import { ExclamationTriangleIcon } from "@heroicons/vue/24/outline";

const emit = defineEmits(["close"]);
const props = defineProps({
    show: {
        default: false,
    },
    maxWidth: {
        default: "2xl",
    },
    closeable: {
        default: true,
    },
});

const close = () => {
    emit("close");
};

const complete = () => {
    confirming.value = false;
    emit("confirmed");
};
</script>

<template>
    <Modal :show="show" :max-width="maxWidth" :closeable="closeable" @close="close">
        <div class="p-3 text-center space-y-3">
            <slot name="icon">
                <div class="flex items-center justify-center w-12 h-12 mx-auto bg-red-100 rounded-full">
                    <ExclamationTriangleIcon class="w-6 h-6 text-red-600" aria-hidden="true" />
                </div>
            </slot>

            <div>
                <slot name="title" />
            </div>

            <slot name="content" />

            <slot name="footer" />
        </div>
    </Modal>
</template>
