<script setup>
import { Link } from '@inertiajs/vue3';
import { ref, computed } from 'vue';
import ConfirmationDialog from '@/common/Components/UI/Alerts/ConfirmationDialog.vue';
import pick from 'lodash';
import { useLang } from '@/common/Composables';

const { lang } = useLang();

const emit = defineEmits(['confirmed']);
const props = defineProps({
    as: {
        type: [String, Object],
        default: 'button',
    },
    title: { type: String, default: null },
    message: { type: String, default: null },
    cancelLabel: { type: String, default: null },
    confirmLabel: { type: String, default: null },
});

const title = computed(() => props.title ?? lang.value?.confirmation ?? 'Are you sure?');
const message = computed(
    () =>
        props.message ??
        lang.value?.confirmation_message ??
        'This action is destructive and not reversible',
);
const cancelLabel = computed(() => props.cancelLabel ?? lang.value?.cancel ?? 'Cancel');
const confirmLabel = computed(() => props.confirmLabel ?? lang.value?.confirmLabel ?? 'Confirm');

const confirming = ref(false);

const show = () => {
    confirming.value = true;
};

const cancel = () => {
    confirming.value = false;
};

const complete = () => {
    confirming.value = false;
    emit('confirmed');
};

const state = {
    show,
    cancel,
    complete,
    title: title.value,
    message: message.value,
    cancelLabel: cancelLabel.value,
    confirmLabel: confirmLabel.value,
};
</script>

<template>
    <slot v-bind="{ ...state }" />

    <ConfirmationDialog :show="confirming" @close="cancel">
        <template #title>
            <div class="font-display font-bold">
                <slot name="modal.title">{{ title }}</slot>
            </div>
        </template>
        <template #content>
            <div class="font-display">
                <slot name="modal.content">
                    {{ message }}
                </slot>
            </div>
        </template>
        <template #footer>
            <slot name="modal.footer" v-bind="{ ...state }">
                <div class="flex items-center justify-end space-x-3">
                    <slot name="modal.confirm.button" v-bind="{ ...state }">
                        <button
                            class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 supports-hover:hover:bg-gray-50 sm:col-start-1 sm:mt-0 sm:text-sm"
                            @click="cancel"
                        >
                            {{ cancelLabel }}
                        </button>
                    </slot>

                    <slot name="modal.confirm.button" v-bind="{ ...state }">
                        <button
                            class="inline-flex w-full cursor-pointer items-center justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 supports-hover:hover:bg-gray-700 sm:col-start-2 sm:text-sm"
                            @click.prevent="complete"
                        >
                            <slot name="modal.confirm.label"> {{ confirmLabel }} </slot>
                        </button>
                    </slot>
                </div>
            </slot>
        </template>
    </ConfirmationDialog>
</template>
