<script setup>
import { Link } from '@inertiajs/vue3';
import { ref, computed } from 'vue';
import ConfirmationDialog from '@/common/Components/UI/Alerts/ConfirmationDialog.vue';
import ConfirmationTrigger from '@/common/Components/UI/Alerts/ConfirmationTrigger.vue';
import pick from 'lodash';
import Spinner from '@/common/Components/UI/Progress/Spinner.vue';
import { FormButton } from '@/company/Components';

const emit = defineEmits(['confirmed']);
const props = defineProps({
    as: {
        type: [String, Object],
        default: 'button',
    },
    title: { type: String, default: null },
    message: { type: String, default: null },
    cancelLabel: { type: String, default: null },
    confirmLabel: { type: String, default: null },
    href: { type: String },
    method: { type: String },
    data: { type: Object },
    headers: { type: Object },
    preserveState: { type: Boolean },
    preserveScroll: { type: Boolean },
    only: { type: Array },
});

const processing = ref(false);

const success = (res, state) => {
    emit('confirmed', res);
    state.complete();
};
</script>

<template>
    <ConfirmationTrigger v-bind="{ ...$attrs, message, title, cancelLabel, confirmLabel, as }">
        <template #default="state">
            <slot v-bind="{ ...state }" />
        </template>

        <template #title="state">
            <slot name="title" v-bind="{ ...state }" />
        </template>
        <template #content="state">
            <slot name="content" v-bind="{ ...state }" />
        </template>
        <template #modal.footer="state">
            <slot name="modal.footer" v-bind="{ ...state }">
                <div class="flex items-center justify-end space-x-3">
                    <slot name="modal.cancel.button" v-bind="{ ...state }">
                        <button
                            class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 supports-hover:hover:bg-gray-50 sm:col-start-1 sm:mt-0 sm:text-sm"
                            @click="state.cancel"
                        >
                            {{ state.cancelLabel }}
                        </button>
                    </slot>

                    <slot name="modal.confirm.button" v-bind="{ ...state }">
                        <FormButton
                            :is="Link"
                            as="form"
                            @start="processing = true"
                            @success="success($event, state)"
                            @finish="processing = false"
                            v-bind="{
                                href,
                                method,
                                data,
                                preserveState,
                                preserveScroll,
                                only,
                                headers,
                            }"
                            class="w-full justify-center"
                            :color="method.toLowerCase() === 'delete' ? 'danger' : 'success'"
                        >
                            <slot name="modal.confirm.label" v-if="!processing">
                                {{ state.confirmLabel }}
                            </slot>
                            <Spinner class="h-6 w-6" v-if="processing" />
                        </FormButton>
                    </slot>
                </div>
            </slot>
        </template>
    </ConfirmationTrigger>
</template>
